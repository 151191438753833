export const RecordingErrorCode = {
  none: 0,
  wrongParamenters: 1,
  sessionIsAlreadyRecording: 2,
  noFreeDisplays: 3,
  sessionWasNotPrepared: 4,
  sessionIsNotRecording: 5,
  sessionWasNotStarted: 6,
  canNotStartFfmpeg: 7,
  ffmpegDied: 8,
  pingTimeout: 9,
  moreThan90Min: 10,
  errorIsUndefined: 11,
  sessionDoesNotExist: 12,
  continueImpossible: 13,
  recordingPageWasCrashed: 14,
}

export const ServerRecordingState = {
  none: 'NONE',
  pending: 'PENDING',
  processing: 'PROCESSING',
  pause: 'PAUSE',
  ready: 'READY',
  error: 'ERROR',
}

export const RecordingType = {
  local: 1,
  cloud: 2,
}

export const LocalRecordingOwnerActionType = {
  stop: 1,
  restart: 2,
}

export const RecordingStatus = {
  stopping: 'STOPPING',
  stopped: 'STOPPED',
  starting: 'STARTING',
  started: 'STARTED',
  pausing: 'PAUSING',
  paused: 'PAUSED',
  continuing: 'CONTINUING',
}

export const DeviceType = {
  unknown: 0,
  webBrowser: 1,
  androidDevice: 2,
  appleDevice: 3,
  androidBrowser: 4,
  iosBrowser: 5,
}

export const ContentType = {
  Unknown: 0,
  Video: 1,
  Text: 2,
  Pdf: 3,
  VideoByUrl: 4,
  CloudFile: 5,
  RecordedVideo: 6,
  Audio: 7,
  DrawingBoard: 8,
  Snapshot: 9,
  Image: 10,
  ConcatenatedVideo: 11,
  WebPage: 12,
  SpatialModel: 13,
  DocDocumentPage: 14,
  PptDocumentPage: 15,
  ExcelDocumentPage: 16,
  File: 17,
  StructureModel: 18,
  StructureModelPdf: 19,
  TwoDLineDrawing: 20,
  TwoDLineDrawingPdf: 21,
  AutodeskFile: 22,
  PointCloud: 23,
  BentleyAnalyticsReportPdf: 24,
  UnencodedVideo: 101,
  UnencodedRecordedVideo: 102,
  UnencodedConcatenatedVideo: 103,
  RecordedCloudFileVideo: 104,
  StreamVideo: 105,
}

export const AnnotationType = {
  Stroke: 0,
  ErasedStroke: 1,
  LaserStroke: 2,
  Text: 3,
  Audio: 4,
  Hotspot: 6,
  LinkedPage: 7,
  AI: 8,
  AIHotspot: 9,
  Box: 10,
  Distance: 11,
  Model: 12,
  Height: 13,
  Area: 14,
  Cylinder: 15,
  Polyline: 16,
  Line: 100,
  Rectangle: 101,
  Ellipse: 102,
  Rectangle_Filled: 103,
  Ellipse_Filled: 104,
  ArrowEnd: 105,
  ArrowBoth: 106,
  Polygon: 107,
}

export const ControlType = {
  Textbox: 1,
  Textarea: 2,
  CheckboxList: 3,
  Radiobuttons: 4,
  Dropdown: 5,
  Tabs: 6,
  Counter: 7,
  Date: 8,
  DateTime: 9,
  Action: 10,
}

export const SendEmailConditionType = {
  AllParticipant: 1,
  SessionOwner: 2,
  SpecificUsers: 3,
}

export const InputType = {
  ControlName: 1,
  Tooltip: 2,
  InitialValue: 3,
  Placeholder: 4,
  IncrementValue: 5,
  IncrementPeriod: 6,
  CounterLimit: 7,
  ControlInfoDescription: 8,
  FormName: 9,
}

export const InputErrorType = {
  EmptyName: 1,
  InitialValueIsNaN: 2,
  EmptyInitialValue: 3,
  EmptyInfoDescription: 10,
}

export const FormItemType = {
  Tab: 1,
  Control: 2,
  Counter: 3,
  Action: 4,
}

export const FormControlType = {
  Basic: 0,
  BodyTemperature: 1,
  PhoneNumber: 2,
  Email: 3,
  BarcodeAndQRCode: 4,
}

export const MobileFormViewMode = {
  Default: 0,
  List: 1,
}

export const AnnotationMode = {
  Normal: 0,
  HideAll: 1,
  ReadOnly: 2,
}

export const AssetType = {
  Unknown: 0,
  Image: 1,
  Video: 2,
  Preview: 3,
  Audio: 4,
  RecordedVideo: 6,
  Thumbnail: 7,
  Snapshot: 10,
  ConcatenatedVideo: 11,
  WebPage: 12,
  SpatialModelImage: 13,
  DocDocument: 14,
  PptDocument: 15,
  ExcelDocument: 16,
  File: 17,
  StructureModel: 18,
  TwoDLineDrawing: 20,
  AutodeskFile: 22,
  PointCloud: 23,
  PotreePointCloud: 24,
  UnencodedVideo: 101,
  UnencodedRecordedVideo: 102,
  UnencodedConcatenatedVideo: 103,
  RecordedCloudFileVideo: 104,
  StreamVideo: 105,
}

export const AssetVideoTypes = [
  AssetType.Video,
  AssetType.RecordedVideo,
  AssetType.ConcatenatedVideo,
  AssetType.UnencodedVideo,
  AssetType.UnencodedRecordedVideo,
  AssetType.UnencodedConcatenatedVideo,
]

export const DrawModeType = {
  Pen: 0,
  Eraser: 1,
  Pointer: 2,
  Text: 3,
  Selection: 4,
  Cursor: 5,
}

export const FinalizeStrokePolicy = {
  AllStrokes: 0,
  FirstAndLastStroke: 1,
}

export const PageType = {
  Normal: 0,
  Text: 1,
  EncodingPending: 2,
}

export const ConditionTypes = {
  AttachForm: 1,
  SendEmail: 2,
  AddVideoByUrl: 3,
  ShowInfoMessage: 4,
  RunWorkflow: 5,
}

export const ConditionTriggerTypes = {
  AnyEntry: 0,
  Equals: 1,
  GreaterThan: 2,
  LessThan: 3,
  UnansweredQuestions: 4,
}

export const PresenterEvent = {
  updatePresenterState: 'updatePresenterState',
}

export const PresenterMode = {
  None: 0,
  Presenter: 1,
  User: 2,
}

export const ScreenModeType = {
  Draw: 0,
  Navigation: 1,
  Gallery: 2,
  Disabled: 3,
  Text: 4,
  User: 5 /* spectator of Presenter */,
  CloseDraw: 6,
}

export const RequestStatus = {
  Pending: 1,
  Started: 2,
  Finished: 3,
  Canceled: 4,
  Error: 10,
}

export const RequestType = {
  UploadFile: 1,
  DownloadFile: 2,
  Request: 3,
}

export const TabeebConnectEvents = {
  TAKING_SNAPSHOT: 'conference.takingSnapshot',
  USER_SCREENSHARING_STATUS_ON: 'conference.screenShareOn',
  USER_SCREENSHARING_STATUS_OFF: 'conference.screenShareOff',
  ALL_USERS_MUTED: 'conference.allUsersMute',
  REMOTE_USER_MUTED_AUDIO: 'REMOTE_USER_MUTED_AUDIO',
  SNAPSHOT_CREATED: 'conference.snapshotCreated',
  SNAPSHOT_CANCELLED: 'conference.snapshotCancelled',
  SEND_GEOPOSITION: 'conference.sendGeoPosition',
  SET_REMOTE_USER_VIDEO_STATE: 'conference.setRemoteUserVideoState',
  SET_REMOTE_USER_AUDIO_STATE: 'SET_REMOTE_USER_AUDIO_STATE',
  ON_RECORDER_VIDEO_ATTACHED: 'conference.onRecorderVideoAttached',
  ON_VIDEO_PAUSED: 'conference.onVideoPaused',
  ON_VIDEO_RESUMED: 'conference.onVideoResumed',
  PICTURE_CREATED: 'conference.pictureCreated',
  ON_REMOTE_AUDIO_INDICATOR_UPDATED: 'conference.onRemoteAudioIndicatorUpdated',
  TRACK_MUTE_UPDATED: 'conference.trackMuteUpdated',
  TRACKS_INITIALIZATION_FAILED: 'conference.tracksInitializationFailed',
}

export function convertTypeToList(type) {
  const result = []
  for (const key in type) {
    result.push({ key, value: type[key] })
  }
  return result
}

export const AnswerStatuses = {
  Pending: 0,
  Approve: 1,
  Reject: 2,
  NoAction: 3,
  ApprovedByAI: 4,
  RejectedByAI: 5,
  ApprovedByReviewer: 6,
  RejectedByReviewer: 7,
}

export const AnswerStatusFilterOptions = {
  NoAction: 'No Action',
  Submitted: 'Submitted',
  Approved: 'Approved',
  Rejected: 'Rejected',
  ApprovedByAI: 'Approved By AI',
  RejectedByAI: 'Rejected By AI',
}

export const AnswerStatusesGroups = {
  [AnswerStatusFilterOptions.Submitted]: [AnswerStatuses.Pending],
  [AnswerStatusFilterOptions.Approved]: [
    AnswerStatuses.Approve,
    AnswerStatuses.ApprovedByAI,
    AnswerStatuses.ApprovedByReviewer,
  ],
  [AnswerStatusFilterOptions.Rejected]: [
    AnswerStatuses.Reject,
    AnswerStatuses.RejectedByAI,
    AnswerStatuses.RejectedByReviewer,
  ],
}

export const SessionNamingConventionType = {
  RenameSession: 'Rename Session',
  CreateSession: 'Create Session',
  CopySession: 'Copy Session',
}

export const ProfileFieldLabels = {
  HeightCm: 'HeightCm',
  HeightFt: 'HeightFt',
  HeightIn: 'HeightIn',
  WeightKg: 'WeightKg',
  WeightLbs: 'WeightLbs',
}

export const ExportAsPdfOptions = {
  PagesInGallery: 0,
  FormQuestions: 1,
}

export const CustomVideoOptions = {
  PagesInGallery: 0,
  FormQuestions: 1,
}

export const ExportGalleryOptions = {
  All: 0,
  CurrentGalleryView: 1,
}

export const ExportFormsToCsvOptions = {
  CurrentForm: 0,
  All: 1,
}

export const DisplayAnswersMode = {
  AllLatest: 0,
  AllMyLatest: 1,
  ByUser: 2,
}

export const AIModelType = {
  CustomVision: 0,
  Custom: 1,
  InternalTrained: 2,
  HuggingFace: 3,
  Roboflow: 4,
}

export const VQAModelType = {
  Custom: 0,
  InternalTrained: 1,
}

export const MLModelType = {
  NLPModel: 2,
  VQAModel: 3,
}

export const AIModelTypeDisplayName = {
  [AIModelType.CustomVision]: 'Custom Vision',
  [AIModelType.Custom]: 'General',
  [AIModelType.InternalTrained]: 'Collaborate',
  [AIModelType.HuggingFace]: 'Hugging Face',
  [AIModelType.Roboflow]: 'Roboflow',
}

export const VQAModelTypeDisplayName = {
  [VQAModelType.Custom]: 'General',
  [VQAModelType.InternalTrained]: 'Collaborate',
}

export const UserRole = {
  User: 0,
  Owner: 1,
  AIUser: 2,
  Reviewer: 3,
  Viewer: 4,
  Labeler: 5,
  Approver: 6,
  SessionGuest: 7,
}

export const SessionRoleDisplayName = {
  [UserRole.User]: 'Collaborator',
  [UserRole.Owner]: 'Owner',
  [UserRole.AIUser]: 'AI User',
  [UserRole.Reviewer]: 'Reviewer',
  [UserRole.Viewer]: 'Viewer',
  [UserRole.Labeler]: 'Labeler',
  [UserRole.Approver]: 'Approver',
  [UserRole.SessionGuest]: 'Session guest',
}

export const FolderUserRole = {
  User: 0,
  Owner: 1,
  Viewer: 2,
  SessionGuest: 3,
}

export const FolderRoleDisplayName = {
  [FolderUserRole.User]: 'Collaborator',
  [FolderUserRole.Owner]: 'Owner',
  [FolderUserRole.Viewer]: 'Viewer',
  [FolderUserRole.SessionGuest]: 'Session guest',
}

export const UserStatus = {
  Registered: 1,
  NotRegistered: 2,
  NotExist: 3,
}

export const UserTenantRole = {
  SystemAdmin: 'SystemAdmin',
  ProgramAdmin: 'ProgramAdmin',
  TenantAdmin: 'TenantAdmin',
  BillingAdmin: 'TenantBillingAdmin',
  SessionAdmin: 'SessionAdmin',
  User: 'User',
  Guest: 'Guest',
  AIAdmin: 'AIAdmin',
  LabelingAdmin: 'LabelingAdmin',
  ViewingAdmin: 'ViewingAdmin',
  BillingSettingsAdmin: 'BillingSettingsAdmin',
}

export const UserTenantRoleId = {
  [UserTenantRole.SystemAdmin]: 3,
  [UserTenantRole.ProgramAdmin]: 1,
  [UserTenantRole.TenantAdmin]: 4,
  [UserTenantRole.BillingAdmin]: 5,
  [UserTenantRole.SessionAdmin]: 2,
  [UserTenantRole.User]: 6,
  [UserTenantRole.Guest]: 8,
  [UserTenantRole.AIAdmin]: 9,
  [UserTenantRole.LabelingAdmin]: 10,
  [UserTenantRole.ViewingAdmin]: 11,
  [UserTenantRole.BillingSettingsAdmin]: 12,
}

export const UserTenantRoleDisplayName = {
  SystemAdmin: 'System Admin',
  ProgramAdmin: 'Program Admin',
  TenantAdmin: 'Tenant Admin',
  TenantBillingAdmin: 'Billing Admin',
  SessionAdmin: 'Session Admin',
  User: 'User',
  AIUser: 'AI User',
  Guest: 'Guest',
  AIAdmin: 'AI Admin',
  LabelingAdmin: 'Labeling Admin',
  ViewingAdmin: 'Viewing Admin',
  BillingSettingsAdmin: 'BillingSettings Admin',
}

export const TenantSessionsMode = {
  Actual: 0,
  Deleted: 1,
}

export const PointCloudModelStatus = {
  NotCreated: 0,
  ModelCreating: 1,
  ModelCreated: 2,
}

export const StructureModelStatus = {
  NotCreated: 0,
  Creating: 1,
  Created: 2,
}

export const NotificationsFilterType = {
  Invite: 0,
  SessionApproval: 1,
  ChangeOwner: 2,
  ShareSession: 3,
  AlertOwner: 4,
  Reminder: 5,
  CalendarEvent: 6,
}

export const ApprovalRequestType = {
  Invitation: 0,
  SessionApproval: 1,
  ChangeOwner: 2,
  ShareSession: 3,
  Notification: 4,
  Reminder: 5,
  CalendarEvent: 6,
}

export const CompressionType = {
  Unscaled: 0,
  Scaled: 1,
  Lowest: 2,
}

export const PolicyType = {
  TermsOfUse: 0,
  PrivacyPolicy: 1,
  TenantTerms: 2,
}

export const NotificationType = {
  InviteToContent: 100,
  ContentActivity: 200,
  ChatMessage: 300,
  StartCall: 400,
  YouNewOwner: 500,
  YouOldOwner: 600,
  ApprovalResult: 700,
  ContentShared: 800,
  AlertOwner: 900,
  ReviewContent: 1000,
  ReviewReworkedContent: 1010,
  InContentReview: 1020,
  YouNewReviewer: 1100,
  YouOldReviewer: 1200,
  ReviewCompleted: 1300,
  AddUserToContent: 1400,
  AnswerQuestionReminder: 1500,
  AddUsersToFolder: 1600,
  LinkContentsApprovalResult: 1800,
  UnreadTrainingMaterialReminder: 1900,
  TrainingMaterialAssigned: 1910,
}

export const NotificationEventType = {
  PageAdded: 100,
  PageDeleted: 200,
  PageUpdated: 300,
  PageThumbnailUpdated: 301,
  PageVideoRestored: 302,
  UserRemoved: 400,
  ShowMessage: 500,
  WorkflowFailed: 600,
  PageStatusUpdated: 700,
}

export const TrackingEventType = {
  CheckIn: 0,
  CheckOut: 1,
  Manual: 2,
}

export const TrackingAnnotationType = {
  Point: 0,
  Rectangle: 1,
  Circle: 2,
}

export const ContentSharingType = {
  Shared: 0,
  Published: 1,
  ForReview: 2,
  ContentsMerging: 3,
  LinkedContentsMerging: 4,
  MergeForReview: 5,
  MergeReviewedData: 6,
}

export const ContentSharingModelType = {
  SharedByOwner: 0,
  SharedByAdmin: 1,
  ForReview: 2,
  ContentsMerging: 3,
  LinkedContentsMerging: 4,
  MergeForReview: 5,
  MergeReviewedData: 6,
}

export const ContentReviewStatus = {
  Pending: 0,
  Active: 1,
  FeedbackSent: 2,
  Completed: 3,
}

export const ErrorCode = {
  ModelInvalid: 1001,
  PhoneNumberAlreadyExist: 1112,
  TenantFeatureDisabled: 1556,
  DeviceAlreadyExists: 2750,
  PageNotFound: 1800,

  ProjectNotDeleted: 3152,
  ProjectNotFound: 3153,
  NoDeleteProjectWithIterations: 3154,
  NoTrainProjectWithIncompleteIteration: 3155,

  IterationNotCreated: 3250,

  DeleteAttachedAIObjectTenantNotAllowed: 2404,
  AIObjectTenantAlreadyExists: 2405,
  NoUpdateOfAIObjectWithConnections: 2406,

  SelectionListAlreadyExists: 4455,
}

export const SystemOfMeasures = {
  Metric: 0,
  Imperial: 1,
}

export const GoogleUnitSystem = {
  // google.maps.UnitSystem.IMPERIAL
  Imperial: 0.0,

  // google.maps.UnitSystem.METRIC
  Metric: 1.0,
}

export const SystemOfMeasuresToGoogleUnitSystem = {
  [SystemOfMeasures.Metric]: GoogleUnitSystem.Metric,
  [SystemOfMeasures.Imperial]: GoogleUnitSystem.Imperial,
}

export const SystemOfMeasureDisplayName = {
  Metric: 'Metric',
  Imperial: 'Imperial',
}

export const Genders = {
  Male: 1,
  Female: 2,
  Other: 3,
}

export const GenderDisplayName = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
}

export const TenoviSensorCodes = {
  BPM: 10,
  PulseOx: 11,
  Glucometer: 12,
  Scale: 13,
  Thermometer: 14,
  Watch: 15,
  PeakFlowMeter: 17,
}

export const TenoviSensorCodeDisplayNames = {
  [TenoviSensorCodes.BPM]: 'Blood pressure monitor',
  [TenoviSensorCodes.PulseOx]: 'Pulse & Oxygen saturation',
  [TenoviSensorCodes.Glucometer]: 'Glucometer',
  [TenoviSensorCodes.Scale]: 'Scale',
  [TenoviSensorCodes.Thermometer]: 'Thermometer',
  [TenoviSensorCodes.Watch]: 'Watch',
  [TenoviSensorCodes.PeakFlowMeter]: 'Peak Flow Meter',
}

export const TenoviDeviceNameTypes = {
  TenoviBpmS: 1,
  TenoviBpmSSignature: 2,
  TenoviBpmL: 3,
  TenoviBpmLSignature: 4,
  TenoviPulseOx: 5,
  TenoviScale: 6,
  TenoviGlucometer: 7,
  TenoviWatch: 8,
}

export const TenoviDeviceNameTypeDisplayNames = {
  TenoviBpmS: 'Tenovi BPM - S',
  TenoviBpmSSignature: 'Tenovi BPM - S - Signature',
  TenoviBpmL: 'Tenovi BPM - L',
  TenoviBpmLSignature: 'Tenovi BPM - L - Signature',
  TenoviPulseOx: 'Tenovi Pulse Ox',
  TenoviScale: 'Tenovi Scale',
  TenoviGlucometer: 'Tenovi Glucometer',
  TenoviWatch: 'Tenovi Watch',
}

export const DeviceCategoryType = {
  MultiPurposeHealthDevice: 1,
  Thermometer: 2, // Temperature
  PressureMonitor: 3, // BloodPressureSystolic + BloodPressureDiastolic
  SugarMonitor: 4, // GlucoseLevel
  Pulsemeter: 5, // Pulse + RestingPulse
  PulseOximeter: 6, // OxygenSaturation + PerfusionIndex
  Scale: 7, // Weight
  Stadiometer: 8, // Height
  PeakFlowMeter: 9, // PeakExpiratoryFlow
  Spirometer: 10, // ForcedExpiratoryVolume
}

export const DeviceCategoryTypeDisplayName = {
  [DeviceCategoryType.MultiPurposeHealthDevice]: 'Multi Purpose Health Device',
  [DeviceCategoryType.Thermometer]: 'Thermometer',
  [DeviceCategoryType.PressureMonitor]: 'Pressure Monitor',
  [DeviceCategoryType.SugarMonitor]: 'Sugar Monitor',
  [DeviceCategoryType.Pulsemeter]: 'Pulsemeter',
  [DeviceCategoryType.PulseOximeter]: 'Pulse-oximeter',
  [DeviceCategoryType.Scale]: 'Scale',
  [DeviceCategoryType.Stadiometer]: 'Stadiometer',
  [DeviceCategoryType.PeakFlowMeter]: 'Peak Flow Meter',
  [DeviceCategoryType.Spirometer]: 'Spirometer',
}

export const DeviceVendorType = {
  Google: 1,
  Apple: 2,
  Tenovi: 3,
  Life365: 4,
}

export const DeviceVendorTypeDisplayName = {
  [DeviceVendorType.Google]: 'Google',
  [DeviceVendorType.Apple]: 'Apple',
  [DeviceVendorType.Tenovi]: 'Tenovi',
  [DeviceVendorType.Life365]: 'Life 365',
}

export const HealthDataTypes = {
  Temperature: 1,
  BloodPressureSystolic: 2,
  BloodPressureDiastolic: 3,
  GlucoseLevel: 4,
  Pulse: 5,
  OxygenSaturation: 6,
  Weight: 7,
  Height: 8,
  RestingPulse: 9,
  PerfusionIndex: 10,
  PeakExpiratoryFlow: 11,
  ForcedExpiratoryVolume: 12,
}

export const IOTDeviceUnitType = {
  DegreesCelsius: 1,
  DegreesFahrenheit: 2,
  BeatsPerMinute: 3,
  MillimetersOfMercury: 4, // mmHg
  Percentage: 5,
  MillimolesPerLiter: 6, // mmol/L
  Kilogram: 7,
  Pound: 8,
  Foot: 9,
  Inch: 10,
  Meter: 11,
  Centimeter: 12,
  MilligramsPerDeciliter: 13, // mg/dL
  LitersPerMinute: 14, // L/min
  Liters: 15,
}

export const HealthDataTypesAutocomplete = [
  { name: 'Pulse', dataType: HealthDataTypes.Pulse },
  { name: 'RestingPulse', dataType: HealthDataTypes.RestingPulse },
  { name: 'BloodPressureSystolic', dataType: HealthDataTypes.BloodPressureSystolic },
  { name: 'BloodPressureDiastolic', dataType: HealthDataTypes.BloodPressureDiastolic },
  { name: 'GlucoseLevel', dataType: HealthDataTypes.GlucoseLevel },
  { name: 'OxygenSaturation', dataType: HealthDataTypes.OxygenSaturation },
  { name: 'Temperature', dataType: HealthDataTypes.Temperature },
  { name: 'Height', dataType: HealthDataTypes.Height },
  { name: 'Weight', dataType: HealthDataTypes.Weight },
  { name: 'PerfusionIndex', dataType: HealthDataTypes.PerfusionIndex },
  { name: 'PeakExpiratoryFlow', dataType: HealthDataTypes.PeakExpiratoryFlow },
  { name: 'ForcedExpiratoryVolume', dataType: HealthDataTypes.ForcedExpiratoryVolume },
]

export const HealthDataTypesDisplayName = {
  Temperature: 'Body Temperature',
  BloodPressureSystolic: 'Blood Pressure Systolic',
  BloodPressureDiastolic: 'Blood Pressure Diastolic',
  GlucoseLevel: 'Blood Glucose',
  Pulse: 'Heart Rate',
  OxygenSaturation: 'Oxygen Saturation',
  Weight: 'Weight',
  Height: 'Height',
  RestingPulse: 'Resting Heart Rate',
  PerfusionIndex: 'Perfusion Index',
  PeakExpiratoryFlow: 'Peak Expiratory Flow',
  ForcedExpiratoryVolume: 'Forced Expiratory Volume',
}

export const IOTDeviceUnitTypeDisplayName = {
  DegreesCelsius: 'Degrees Celsius',
  DegreesFahrenheit: 'Degrees Fahrenheit',
  BeatsPerMinute: 'Beats per minute',
  MillimetersOfMercury: 'Millimeters of mercury',
  Percentage: 'Percentage',
  MillimolesPerLiter: 'Millimoles per liter',
  Kilogram: 'Kilogram',
  Pound: 'Pound',
  Foot: 'Foot',
  Inch: 'Inch',
  Meter: 'Meter',
  Centimeter: 'Centimeter',
  MilligramsPerDeciliter: 'Milligrams per deciliter',
  LitersPerMinute: 'Liters per minute',
  Liters: 'Liters',
}

export const IOTDeviceUnitTypeChartName = {
  DegreesCelsius: '°C',
  DegreesFahrenheit: '°F',
  BeatsPerMinute: 'bpm',
  MillimetersOfMercury: 'mmHg',
  Percentage: '%',
  MillimolesPerLiter: 'mmol/L',
  Kilogram: 'kg',
  Pound: 'lbs',
  Foot: 'ft',
  Inch: 'in',
  Meter: 'm',
  Centimeter: 'cm',
  MilligramsPerDeciliter: 'mg/dL',
  LitersPerMinute: 'L/min',
  Liters: 'L',
}

export const HealthStatisticsTimeLine = {
  OneMonth: 'one_month',
  SixMonth: 'six_months',
  OneYear: 'one_year',
  YTD: 'ytd',
  All: 'all',
}

export const HealthStatisticsTimeLineDisplayName = {
  OneMonth: '1M',
  SixMonth: '6M',
  OneYear: '1Y',
  YTD: 'YTD',
  All: 'ALL',
}

export const ThermometerUnitTypes = {
  DegreesCelsius: 1,
  DegreesFahrenheit: 2,
}

export const Life365Programs = {
  Life365ProgramJACS: 0,
}

export const Life365ProgramsDisplayName = {
  [Life365Programs.Life365ProgramJACS]: 'Life 365 Program (JACS)',
}

export const Life365ProgramsIds = {
  [Life365Programs.Life365ProgramJACS]: '1e9c48a6-95f7-4629-937a-8620010be373',
}

export const DeviceAddAttributeType = {
  Life365ProgramId: 1,
  TenoviSensorCode: 2,
}

export const ContentState = {
  Created: 0,
  Pending: 1,
  Rejected: 2,
}

export const ActivityTypeFilterItems = {
  all: 'All',
  AIFlag: 'AI Flag',
  Flag: 'Flag',
  Drawing: 'Drawing',
  Image: 'Image',
  Shape: 'Shape',
  Text: 'Text',
  Audio: 'Voice Over',
}

export const OverlayPosition = {
  TopLeft: 0,
  BottomRight: 1,
}

export const ContentMetadataPosition = {
  Left: '0',
  Center: '1',
}

export const SessionStatus = {
  Live: 1,
  InProgress: 2,
  Published: 3,
  Shared: 4,
  CallEnded: 5,
  CheckedIn: 6,
  CheckedOut: 7,
  CopiedReview: 8,
  NotStarted: 0,
}

export const SessionStatusDisplayName = {
  [SessionStatus.Live]: 'Live',
  [SessionStatus.InProgress]: 'In Progress',
  [SessionStatus.Published]: 'Published',
  [SessionStatus.Shared]: 'Shared',
  [SessionStatus.CallEnded]: 'Call Ended',
  [SessionStatus.CheckedIn]: 'Checked-in',
  [SessionStatus.CheckedOut]: 'Checked-out',
  [SessionStatus.NotStarted]: 'Not Started',
  [SessionStatus.CopiedReview]: 'Copied Review',
}

export const ReviewStatus = {
  NotStarted: 0,
  ReadyForReview: 1,
  InReview: 2,
  SessionFeedbackSent: 3,
  Complete: 4,
}

export const ReviewStatusDisplayName = {
  [ReviewStatus.NotStarted]: 'Not Started',
  [ReviewStatus.InReview]: 'In Review',
  [ReviewStatus.ReadyForReview]: 'Ready for Review',
  [ReviewStatus.SessionFeedbackSent]: 'Feedback Sent',
  [ReviewStatus.Complete]: 'Complete',
}

export const SessionPermission = {
  AddGalleryContent: 0,
  ExportGalleryContent: 1,
  AnswerQuestions: 2,
  RejectApprove: 3,
  AddForms: 4,
  StartCalls: 5,
  InviteUsers: 6,
  CompleteReview: 7,
  SendReviewFeedback: 8,
  DeleteAllFormAnswers: 9,
  ExportSession: 10,
  ExportFormAsCsv: 11,
  ExportFormAsPdf: 12,
  DeleteRenameAllGalleryContent: 13,
  RemoveUsers: 14,
  ChangeUserRole: 15,
  SetVideobridge: 16,
  RenameSession: 17,
  SendChatMessages: 18,
  AddAiAnnotations: 19,
  AddAnnotations: 20,
  MainScreenToolbarTracking: 21,
  RotateImages: 22,
  AlertHost: 23,
  DeleteRenameOwnGalleryContent: 24,
  PublishSessions: 25,
  JoinCall: 26,
  AddDeleteReminder: 27,
  GetContentMetadata: 28,
  HealthDataView: 29,
  SendForInContentReview: 30,
  CompleteOrFeedbackInContentReview: 31,
  ManagePageFolderPermissions: 32,
  ManageTrainingMaterials: 33,
}

export const FolderPermission = {
  CreateContent: 1,
  AddUsers: 2,
  EditUsers: 3,
  MoveContent: 4,
  LeaveFolder: 5,
  DeleteFolder: 6,
}

export const PageFolderAccessViewType = {
  Public: 'Everyone in the session',
  Private: 'Visible for me only',
  Shared: 'Select users that have access',
}

export const PageFolderPermission = {
  FullAccess: 1, // split it, when add new permission (e.g. Read and Write)
}

export const PageFolderPermissionLabel = {
  [PageFolderPermission.FullAccess]: 'Full Access',
}

export const TenantPermission = {
  CreateSessions: 1,
  ShareContents: 2,
  CreateForms: 3,
  TenantSettings: 4,
  BillingSettings: 5,
  AISettings: 6,
  AllSessionsAccess: 7,
  StoragesAccess: 8,
  AllFormsView: 9,
  ReviewRejectInAnySession: 10,
  AllTenantsAccess: 11,
  AllUsersView: 12,
  Devices: 13,
  Analytics: 14,
  EditPolicies: 15,
  BulkCopy: 16,
  Reports: 17,
  SessionApprovalSettings: 18,
  CreateFolders: 19,
  PublishForms: 20,
  GeosearchSettings: 21,
  SessionNamingConventionSettings: 22,
  OverlaySettings: 23,
  PredictionSettings: 24,
  DetectionSettings: 25,
  SessionSharingSettings: 26,
  ReviewingSettings: 27,
  FtpSettings: 28,
  FtpExportSettings: 29,
  AppearanceSettings: 30,
  TrackingSettings: 31,
  VideoBridgeSettings: 32,
  PremiumFeaturesSettings: 33,
  FormBuilderSettings: 34,
  SsoSettings: 35,
  MobileAppSettings: 36,
  ExportReceiversSettings: 37,
  UploadUsers: 38,
  AIStateManagment: 39,
  SwitchContentOwner: 40,
  AddContentUsers: 41,
  SwitchContentReviewer: 42,
  UpdateSessionStorage: 43,
  JoinContentReview: 44,
  ReminderSettings: 45,
  ThreeDimensionalModelSettings: 46,
  PublishFormsSettings: 47,
  CreateReports: 48,
  CalendarSettings: 49,
  ChangeContentMetadata: 50,
  AllContentMetadataAccess: 51,
  CreatePublishFormWhenRestricted: 52,
  RolesSettings: 53,
  HealthKitSettings: 54,
  AccountManagementSettings: 55,
  PublishFormFolders: 56,
  AllCalendarEventsAccess: 57,
  ShowAddVQAButton: 58,
  ThreeDimensionalModelsManagement: 59,
  SendApproveOrRejectNotificationSettings: 60,
  AnalyticsReports: 61,
  CertificatesSettings: 62,
  ModelTraining: 64,
  ChangeContentReviewStatus: 65,
  CustomIframeSettings: 66,
  ManageSystemAnnouncements: 67,
  ManageTenantAnnouncements: 68,
  AIReport: 69,
  PublishAIReport: 70,
  PublishAIReportSettings: 71,
  AIReportSettings: 72,
  ManageTenantGeneratedReports: 73,
  ManageTenantScheduledGeneratedReports: 74,
  ManageTenantGeneratedReportsReceivers: 75,
  CertificatesTenantScope: 76,
  CertificatesGlobalScope: 77,
  ContractsManagement: 78,
  ManageQualifications: 79,
  ManageWorkflows: 80,
  ManageTrainingMaterials: 81,
  ContentApproval: 82,
  BulkImport: 83,
  ManageCreateContentRestriction: 84,
  CreateContentUnderRestriction: 85,
}

export const FormPermissions = {
  ViewAndAnswerForm: 1,
  ViewOtherUsersAnswers: 2,
}

export const FormPermissionsNames = {
  [FormPermissions.ViewAndAnswerForm]: 'See and answer form',
  [FormPermissions.ViewOtherUsersAnswers]: "See other's answers",
}

export const ReminderType = {
  AnswerFormQuestion: 0,
}

export const ReminderRepeatMode = {
  NoRepeat: 0,
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Annually: 4,
  EveryWeekday: 5,
  Custom: 6,
}

export const ReminderCustomRepeatMode = {
  Minute: 0,
  Hour: 1,
  Day: 2,
  Week: 3,
  Month: 4,
  Year: 5,
}

export const ReminderCustomRepeatEndOnType = {
  Never: 0,
  Date: 1,
  Occurrences: 2,
}

export const DaysOfWeek = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 4,
  Wednesday: 8,
  Thursday: 16,
  Friday: 32,
  Saturday: 64,
}

export const TopicType = {
  Session: 1,
  Page: 2,
  FormAnswer: 3,
  FormControl: 4,
}

export const VQAPolicyRule = {
  AND: 0,
  OR: 1,
  NAND: 2,
  NOR: 3,
  XOR: 4,
  XNOR: 5,
}

export const VQAPolicyRuleDisplayName = {
  [VQAPolicyRule.AND]: 'AND',
  [VQAPolicyRule.OR]: 'OR',
  [VQAPolicyRule.NAND]: 'NOT AND (NAND)',
  [VQAPolicyRule.NOR]: 'NOT OR (NOR)',
  [VQAPolicyRule.XOR]: 'Exclusive OR (XOR)',
  [VQAPolicyRule.XNOR]: 'Exclusive NOT OR (XNOR)',
}

export const CalendarEventType = {
  Event: 0,
  Appointment: 1,
  AppointmentSlots: 2,
}

export const CalendarEventAttendeeInvitationStatus = {
  Pending: 0,
  Declined: 1,
  Accepted: 2,
}

export const CalendarEventVisibility = {
  Public: 0,
  Private: 1,
}

export const CalendarEventTransparency = {
  Opaque: 0,
  Transparent: 1,
}

export const CalendarEventVisibilityDisplayName = {
  [CalendarEventVisibility.Public]: 'Public',
  [CalendarEventVisibility.Private]: 'Private',
}

export const CalendarEventTransparencyDisplayName = {
  [CalendarEventTransparency.Opaque]: 'Busy',
  [CalendarEventTransparency.Transparent]: 'Free',
}

export const FetchStatus = {
  Idle: 0,
  Loading: 1,
  Loaded: 2,
  Failed: 3,
}

export const CalendarSubscriptionDisplay = {
  Hide: 0,
  Display: 1,
  DisplayThisOnly: 2,
}

export const MainScreenContentType = {
  Gallery: 0,
  Tracking: 1,
  Call: 2,
  PointCloudGeoSearch: 3,
}

export const TabPanel = {
  // left
  Activities: 'activities',
  Chat: 'chat',
  Forms: 'forms',
  ContentSharings: 'content-sharings',
  ContentTrainingMaterials: 'content-training-materials',
  // right
  Gallery: 'gallery',
  FileUploads: 'fileUploads',
  TrashBin: 'trashBin',
  // bottom
  BentleyAnalyticsReport: 'bentley-analytics-report',
  BentleyModel: 'bentley-model',
  ClassificationAnnotations: 'classification-annotations',
  CustomVideo: 'custom-video',
  LinkedContentsSelectedPages: 'linked-contents-pages',
  ReviewSession: 'review-session',
  ShareSession: 'share-session',
  SpatialView: 'spatial-view',
  SplatModel: 'splat-model',
  StructureModel: 'structure-model',
  ThreeDModel: '3d-model',
  TowerSideProfile: 'tower-side-profile',
  TwoDLineDrawing: '2d-line-drawing',
  Users: 'users',
}

export const TabPanelLocation = {
  Left: 'left',
  Right: 'right',
  Bottom: 'bottom',
}

export const RotateType = {
  Rotate90Clockwise: 0,
  Rotate90Anticlockwise: 1,
  FlipHorizontal: 2,
  FlipVertical: 3,
}

export const InviteStatus = {
  Pending: 0,
  Accepted: 1,
  Declined: 2,
}

export const AnnotationSource = {
  // VQA
  ImageAndQuestionPairs: 0,
  ImageAndNlpTagSets: 1,
  // CV
  ImageAndClassificationAnnotations: 100,
  ImageAndBoundingBoxAnnotations: 102,
  ImageAndPolygonAnnotations: 103,
}

export const TrainingOptions = {
  VQA: 0,
  ImageClassification: 100,
  ObjectDetection: 101,
}

export const ProjectType = {
  VQA: 0,
  ImageClassification: 100,
  ObjectDetection: 101,
}

export const ProjectTypeDisplayName = {
  [ProjectType.VQA]: 'Visual Question Answering',
  [ProjectType.ImageClassification]: 'Image Classification',
  [ProjectType.ObjectDetection]: 'Object Detection',
}

export const IterationType = {
  FineTuneCLIP: 1,
  ObjectDetection: 2,
  InstanceSegmentation: 3,
  ClassificationWithPretrainedEncoder: 4,
  ImageNLPClassificationWithPretrainedEncoder: 8,
  ObjectDetectionYolox: 9,
}

export const IterationStatus = {
  NotStarted: 0,
  Starting: 1,
  Provisioning: 2,
  Preparing: 3,
  Queued: 4,
  Running: 5,
  Finalizing: 6,
  CancelRequested: 7,
  Completed: 8,
  Failed: 9,
  Canceled: 10,
  NotResponding: 11,
  SentToStart: 100,
  FailedToSendToStart: 101,
}

export const IterationStatusDisplayName = {
  [IterationStatus.NotStarted]: 'Not started',
  [IterationStatus.Starting]: 'Starting',
  [IterationStatus.Provisioning]: 'Provisioning',
  [IterationStatus.Preparing]: 'Preparing',
  [IterationStatus.Queued]: 'Queued',
  [IterationStatus.Running]: 'Running',
  [IterationStatus.Finalizing]: 'Finalizing',
  [IterationStatus.CancelRequested]: 'CancelRequested',
  [IterationStatus.Completed]: 'Completed',
  [IterationStatus.Failed]: 'Failed',
  [IterationStatus.Canceled]: 'Canceled',
  [IterationStatus.NotResponding]: 'NotResponding',
  [IterationStatus.SentToStart]: 'Sent for processing',
  [IterationStatus.FailedToSendToStart]: 'Failed to be sent for processing',
}

export const IterationDeploymentStatus = {
  NotDeployed: 0,
  Succeeded: 1,
  Stopped: 2,
  Failed: 3,
  Creating: 4,
  Updating: 5,
  Deleting: 6,

  SentToDeploy: 100,
  FailedToSendToDeploy: 101,
}

export const IterationDeploymentStatusDisplayName = {
  [IterationDeploymentStatus.NotDeployed]: 'Not deployed',
  [IterationDeploymentStatus.Succeeded]: 'Running',
  [IterationDeploymentStatus.Stopped]: 'Stopped',
  [IterationDeploymentStatus.Failed]: 'Failed',
  [IterationDeploymentStatus.Creating]: 'Creating endpoint',
  [IterationDeploymentStatus.Updating]: 'Deploying',
  [IterationDeploymentStatus.Deleting]: 'Deleting',
  [IterationDeploymentStatus.SentToDeploy]: 'Sent for deployment',
  [IterationDeploymentStatus.FailedToSendToDeploy]: 'Failed to be sent for deployment',
}

export const ProjectEventType = {
  IterationAdded: 0,
  IterationTrainingStatusUpdated: 1,
  IterationDeploymentStatusUpdated: 2,
}

export const ProjectSearchFieldType = {
  None: 0,
  ContentSearch: 1,
  AIObjectSearch: 2,
  FormSearch: 3,
  UserSearch: 4,
  ApproverSearch: 5,
  ReviewerSearch: 6,
  AnswererSearch: 7,
  FormControlSearch: 8,
}

export const SortingOrder = {
  Ascending: 'asc',
  Descending: 'desc',
}

export const AiExpressionNodeType = {
  ClassFilter: 1,
  ModelRunner: 2,
  Reducer: 3,
  SetComposer: 4,
  LogicalValuesComposer: 5,
  ConstantNumber: 6,
  ConstantString: 7,
  ConstantBoolean: 8,
  PairSetComposer: 9,
  ReductionFilter: 10,
  Projector: 11,
  ValuesComparator: 12,
  ValuesCalculator: 13,
}

export const AiExpressionNodeResultType = {
  Boolean: 1,
  Date: 2,
  DateTime: 3,
  Decimal: 4,
  Float: 5,
  Integer: 6,
  String: 7,
  Set: 8,
  PairSet: 9,
}

export const PermissionLinkedObjectTypes = {
  TenantRole: 1,
  ContentRole: 2,
  FolderRole: 3,
  ContentRoleForFormForTenant: 4,
  ContentRoleForTenant: 5,
  ContentUserFormPermissionSet: 6,
}

export const SearchStatus = {
  NotStarted: 1,
  InProgress: 2,
  Completed: 3,
}

export const ChartType = {
  Line: 'line',
  Area: 'area',
  Bar: 'bar',
  Histogram: 'histogram',
  Pie: 'pie',
  Donut: 'donut',
  Radialbar: 'radialBar',
  Scatter: 'scatter',
  Bubble: 'bubble',
  Heatmap: 'heatmap',
  Treemap: 'treemap',
  Boxplot: 'boxPlot',
  Candlestick: 'candlestick',
  Radar: 'radar',
  Polararea: 'polarArea',
  Rangebar: 'rangeBar"',
}

export const UserSignatureType = {
  Text: 1,
  Drawing: 2,
}

export const ContentFormRecordType = {
  Manual: 0,
  UserSignature: 1,
}

export const UploadContentType = {
  Page: 1,
  FormThumbnail: 2,
  SettingData: 3,
  AiExpressionImage: 4,
  ArticleDocument: 5,
  FormData: 6,
}

export const CertificateScopeType = {
  UserCertificate: 1, // global scope
  ProfileCertificate: 2, // tenant scope
  TenantCertificate: 3,
}

export const CertificateScopeTypeDisplayName = {
  [CertificateScopeType.UserCertificate]: 'User', // global scope
  [CertificateScopeType.ProfileCertificate]: 'Profile', // tenant scope
  [CertificateScopeType.TenantCertificate]: 'Tenant',
}

export const CertificateStatusType = {
  PendingReview: 1,
  Approved: 2,
  Rejected: 3,
  Expired: 4,
  Draft: 5,
  Obsolete: 6,
  Processed: 7,
}

export const CertificateStatusTypeDisplayName = {
  [CertificateStatusType.PendingReview]: 'Pending review',
  [CertificateStatusType.Approved]: 'Approved',
  [CertificateStatusType.Rejected]: 'Rejected',
  [CertificateStatusType.Expired]: 'Expired',
  [CertificateStatusType.Draft]: 'Draft',
  [CertificateStatusType.Obsolete]: 'Obsolete',
  [CertificateStatusType.Processed]: 'Processed',
}

export const PeriodUnitType = {
  Day: 1,
  Month: 2,
  Year: 3,
}

export const PeriodUnitTypeDisplayName = {
  [PeriodUnitType.Day]: 'Day(s)',
  [PeriodUnitType.Month]: 'Month(s)',
  [PeriodUnitType.Year]: 'Year(s)',
}

export const UserAssignedQualificationStatusType = {
  Pending: 1,
  Active: 2,
  Revoked: 3,
  CertificateExpired: 4,
  VersionExpired: 5,
  CertificateTypeDeleted: 6,
  QualificationDeleted: 7,
}

export const UserAssignedQualificationStatusTypeDisplayName = {
  [UserAssignedQualificationStatusType.Pending]: 'Waiting for certificates',
  [UserAssignedQualificationStatusType.Active]: 'Active',
  [UserAssignedQualificationStatusType.Revoked]: 'Revoked',
  [UserAssignedQualificationStatusType.CertificateExpired]: 'Certificate expired',
  [UserAssignedQualificationStatusType.VersionExpired]: 'Version expired',
  [UserAssignedQualificationStatusType.CertificateTypeDeleted]: 'Type deleted',
  [UserAssignedQualificationStatusType.QualificationDeleted]: 'Qualification deleted',
}

export const TabeebColors = {
  Black: '#000000',
  Blue: '#0002F9',
  DarkSilver: '#6D6D6D',
  DeepGreen: '#0F7301',
  FluorescentBlue: '#1FFFFF',
  FrenchViolet: '#6900CD',
  Fuchsia: '#FF00FF',
  LightSilver: '#D9D9D9',
  NavyBlue: '#00006F',
  NeonGreen: '#23FF07',
  Orange: '#F86601',
  Red: '#FB000E',
  VividRaspberry: '#F8016C',
  White: '#FFFFFF',
  Yellow: '#FFFE09',
}

export const AIObjectPropertyType = {
  Json: 0,
  String: 1,
  IntNumber: 2,
  DoubleNumber: 3,
  DateTime: 4,
  Dropdown: 5,
  // Checkbox: 6,
  Url: 7,
  Boolean: 8,
  Latitude: 9,
  Longitude: 10,
}

export const AIObjectPropertyTypeDisplayName = {
  [AIObjectPropertyType.Json]: 'Json',
  [AIObjectPropertyType.String]: 'String',
  [AIObjectPropertyType.IntNumber]: 'Integer',
  [AIObjectPropertyType.DoubleNumber]: 'Floating point number (float)',
  [AIObjectPropertyType.DateTime]: 'Date and time',
  [AIObjectPropertyType.Dropdown]: 'Dropdown',
  [AIObjectPropertyType.Checkbox]: 'Checkbox',
  [AIObjectPropertyType.Url]: 'Url',
  [AIObjectPropertyType.Boolean]: 'Boolean',
  [AIObjectPropertyType.Latitude]: 'Latitude',
  [AIObjectPropertyType.Longitude]: 'Longitude',
}

export const GraphElementType = {
  None: 0,
  Node: 1,
  Edge: 2,
  EdgeDirected: 3,
}

export const GraphElementTypeDisplayName = {
  [GraphElementType.None]: 'None',
  [GraphElementType.Node]: 'Node',
  [GraphElementType.Edge]: 'Edge',
  [GraphElementType.EdgeDirected]: 'Edge directed',
}

export const UniqueAIObjectConnectionType = {
  Link: 0,
  Projection: 1,
  Intersection: 2,
}

export const UniqueAIObjectConnectionTypeDisplayName = {
  [UniqueAIObjectConnectionType.Link]: 'Link',
  [UniqueAIObjectConnectionType.Projection]: 'Projection',
  [UniqueAIObjectConnectionType.Intersection]: 'Intersection',
}

export const UniqueAIObjectCreationMode = {
  CreateNew: 0,
  SelectExisting: 1,
  CreateNewFromExisting: 2,
}

export const BulkImportStatus = {
  Created: 1,
  ValidationStarted: 2,
  Validated: 3,
  ValidatedWithWarnings: 4,
  ValidatedWithErrors: 5,
  Abandoned: 6,
  ImportStarted: 7,
  Imported: 8,
  ImportedWithErrors: 9,
  ImportedWithWarnings: 10,
  ImportQueued: 11,
}

export const BulkImportStatusDisplayName = {
  [BulkImportStatus.Created]: 'Created',
  [BulkImportStatus.ValidationStarted]: 'Validation started',
  [BulkImportStatus.Validated]: 'Validated',
  [BulkImportStatus.ValidatedWithWarnings]: 'Validated with warnings',
  [BulkImportStatus.ValidatedWithErrors]: 'Validated with errors',
  [BulkImportStatus.Abandoned]: 'Abandoned',
  [BulkImportStatus.ImportStarted]: 'Import started',
  [BulkImportStatus.Imported]: 'Imported',
  [BulkImportStatus.ImportedWithErrors]: 'Import failed',
  [BulkImportStatus.ImportedWithWarnings]: 'Imported with warnings',
  [BulkImportStatus.ImportQueued]: 'Import queued',
}

export const BulkImportItemType = {
  Session: 1,
  CrossSessionObjectRelation: 2,
}

export const BulkImportItemTypeDisplayName = {
  [BulkImportItemType.Session]: 'Session',
  [BulkImportItemType.CrossSessionObjectRelation]: 'Cross session object relation',
}

export const ImportResultType = {
  ValidationWarning: 1,
  ValidationError: 2,
  ImportError: 3,
  ImportWarning: 4,
}

export const ImportResultTypeDisplayName = {
  [ImportResultType.ValidationWarning]: 'Validation warning',
  [ImportResultType.ValidationError]: 'Validation error',
  [ImportResultType.ImportError]: 'Import error',
  [ImportResultType.ImportWarning]: 'Import warning',
}

export const SelectionListStatus = {
  Draft: 0,
  Published: 1,
  Disabled: 2,
}

export const PageStatus = {
  Creating: 0,
  Created: 1,
}
